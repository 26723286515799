<template>
  <div class="wrapper whole-w-h">
    <div class="header">
      <h4>请选择当前诉求类型</h4>
      <span>可以选择跳转入口</span>
    </div>
    <div class="content">
      <div class="item" v-for="item in DATA" :key="item.label" @click="handleChoose(item.routerName)">
        <img :src="item.icon" alt="">
        <label>{{ item.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from './icons/icon1.svg'
import icon2 from './icons/icon2.svg'
import icon3 from './icons/icon3.svg'

export default {
  data() {
    return {
      DATA: [
        {
          label: '我要提意见',
          routerName: 'AdvicePage',
          icon: icon1
        },
        {
          label: '我要找领导',
          routerName: 'LeaderPage',
          icon: icon2
        },
        {
          label: '我要点个赞',
          routerName: 'ThumbPage',
          icon: icon3
        },
      ]
    }
  },
  methods: {
    handleChoose(name) {
      this.$router.push({name})
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 30px 15px;
  background: linear-gradient( 175deg, #FFFFFF 0%, rgba(223,226,253,0.68) 21%, rgba(203,206,237,0.46) 48%, #EBEFFF 100%);
  .header{
    h4{
      font-size: 16px;
      line-height: 40px;
      color: #022042;
    }
    span{
      font-size: 14px;
      color: #626577;
    }
  }
  .content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .item{
      height: 100px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-left: 30px;
      border-radius: 10px;
      backdrop-filter: blur(3px);
      &:nth-child(1){
        background: linear-gradient( 128deg, rgba(244,246,255,0.65) 0%, rgba(240,248,255,0.65) 47%, rgba(244,246,255,0.83) 100%);
      }
      &:nth-child(2){
        background: linear-gradient( 128deg, rgba(248,249,255,0.65) 0%, rgba(239,239,255,0.65) 47%, rgba(252,252,255,0.83) 100%);
      }
      &:nth-child(3){
        background: linear-gradient( 128deg, rgba(244,246,255,0.65) 0%, rgba(255,248,243,0.65) 49%, rgba(244,246,255,0.68) 100%);
      }
      img{
        width: 58px;
      }
      label{
        font-size: 16px;
        color: #12181F;
      }
    }
  }
}
</style>